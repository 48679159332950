import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Table
} from 'antd';
import Bind from 'lodash-decorators/bind';
import styles from './index.module.less';

const noop = () => {};

class StandardTable extends Component {
  static propTypes = {
    tableConfig: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  @Bind
  handleTableChange(pagination, filters, sorter) {
    if (sorter) {
      this.props.tableConfig.tableOnChange(pagination.pageSize, pagination.current, {
        field: sorter.field,
        order: sorter.order
      });
    } else {
      this.props.tableConfig.tableOnChange(pagination.pageSize, pagination.current);
    }
  }

  @Bind
  handleShowSizeChange(current, size) {
    // console.log(current, size);
  }

  render() {
    const {
      columns = [],
      data = [],
      dataKey = '',
      loading = false,
      size = null,
      heightY = null,
      rowSelection = null,
      pagination = false,
      title = null,
      rowClassName = noop,
    } = this.props.tableConfig;
    let paginationOption = {};
    if (pagination && pagination.showSizeChanger) {
      paginationOption = {
        ...pagination,
        onShowSizeChange: (current, size) => {
          console.log(current, size);
        }
      }
    } else {
      paginationOption = {...pagination}
    }
    if (!pagination) {
      paginationOption = false;
    }
    //设置表格宽度
    let widthX = 0;
    for (let item of columns) {
      widthX += item.width
    }
    //设置data的key
    for (let i = 0; i <= data.length - 1; i++) {
      data[i].key = data[i][dataKey] || i;
    }
    console.log(rowClassName);
    return (
      <div>
        <Table
          loading={loading}
          rowSelection={rowSelection}
          scroll={{x: widthX, y: heightY}}
          dataSource={data}
          columns={columns}
          size={size}
          title={title}
          rowClassName={rowClassName}
          pagination={paginationOption}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}


export default StandardTable;
