
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Icon,
} from 'antd';
import Bind from 'lodash-decorators/bind';

import styles from './index.module.less';

class SearchForm extends Component {

  static propTypes = {
    onSearch: PropTypes.func,
  };

  formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
  };

  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      showExpand: true,
    };
  }
  /**
   * 表单搜索事件，需要重写
   *
   * @memberof SearchForm
   */
  @Bind
  onSearch(event) {
    event.preventDefault();
    const { onSearch } = this.props;
    onSearch && onSearch();
  }
  /**
   * 重置按钮点击
   *
   * @memberof SearchForm
   */
  @Bind
  reset() {
    this.props.form.resetFields();
    const { onSearch } = this.props;
    onSearch && onSearch();
  }
  /**
   * 切换展开按钮
   *
   * @memberof SearchForm
   */
  @Bind
  toggleExpand() {
    const { expand } = this.state;
    this.setState({
      expand: !expand,
    });
  }

  /**
   * 提供给外部使用，展开
   *
   * @memberof SearchForm
   */
  expandForm() {
    this.setState({
      expand: true,
    });
  }
  /**
   * 提供给外部使用，收起
   *
   * @memberof SearchForm
   */
  collapseForm() {
    this.setState({
      expand: false,
    });
  }
  /**
   * 渲染查询按钮
   *
   * @returns
   * @memberof SearchForm
   */
  renderSearchBtns(style) {
    const { expand, showExpand } = this.state;
    return (
      <Form.Item className={styles['search-btns']} style={style}>
        <Button type="primary"  htmlType="submit">查 询</Button>
        <Button onClick={this.reset}>重 置</Button>
        {
          showExpand? (
            <span className={styles['expand-btn']} onClick={this.toggleExpand}>
              {!expand? '展开': '收起'} <Icon type={expand? 'up': 'down'}/>
            </span>
          ): null

        }
      </Form.Item>
    );
  }
  /**
   * 需要实现该方法，渲染表单列
   *
   * @memberof SearchForm
   */
  renderFormItems() {}
  render() {
    return (
      <Form className={styles['search-form']} onSubmit={this.onSearch}>
        { this.renderFormItems() }
      </Form>
    );
  }
}


export default SearchForm;
