import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'dva';
import {Bind} from 'lodash-decorators';
import styles from './index.module.less';
import Base from '../../../common/Base';
import {Row, Col, Card, Form, Input, message, Modal, Button} from 'antd';
import moment from 'moment';
import qs from 'qs';
import StandardTable from '../../../components/StandardTable';

const FormItem = Form.Item;


@connect(({global, object, loading, user}) => ({
  loading,
  couponsList: object.couponsList,
  dictionary: user.dictionary
}))
@Form.create()
class AddCouponsModal extends Base {
  constructor(props) {
    super(props);
    this.state = {
      formValues: null,
      selectedRowKeys: [],
      searchFiles: {
        page: 1,
        row: 10
      }
    }
  }

  componentDidMount() {
    const {objectDetail, edit} = this.props;
    this.initList();
    if(objectDetail && objectDetail.coupon.length > 0) {
      let arr = [];
      for(let value of objectDetail.coupon) {
        arr.push(value.key)
      }
      this.setState({
        selectedRowKeys: arr
      })
    }
  }

  @Bind
  initList(fieldsValue = {}) {
    const {dispatch} = this.props;
    const {searchFiles} = this.state;
    dispatch({
      type: 'object/queryCouponsForPage',
      payload: {
        body: {
          ...searchFiles,
          ...fieldsValue,
          dictValidity: '1',
        }
      }
    })
  }

  @Bind
  handleCancel() {
    const {changeVisible} = this.props;
    changeVisible(false);
  }

  @Bind
  handleOk() {
  }

  @Bind
  modalhandleSearch(e) {
    e.preventDefault();
    const {dispatch, form} = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      console.log(fieldsValue);
      this.setState({
        formValues: fieldsValue,
      });
      this.initList(fieldsValue);
    });
  };

  //点击按钮重置搜索方法
  @Bind
  handleFormReset() {
    const {form, dispatch} = this.props;
    form.resetFields();
    this.setState({
      formValues: {},
    });
    this.initList();
  };

  @Bind
  renderGeneralForm() {
    const {form} = this.props;
    const {getFieldDecorator} = form;
    return (
      <div>
        <Form onSubmit={this.modalhandleSearch}>
          <Row gutter={16}>
            <Col span={8}>
              <FormItem label="客户用户名" labelCol={{span: 8, offset: 0}} wrapperCol={{span: 14, offset: 0}}>
                {getFieldDecorator('loginAccount')(<Input placeholder="请输入用户名"/>)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="客户姓名" labelCol={{span: 8, offset: 0}} wrapperCol={{span: 14, offset: 0}}>
                {getFieldDecorator('customerName')(<Input placeholder="请输入姓名"/>)}
              </FormItem>
            </Col>
            <Col span={8}>
            <span className={styles.submitButtons}>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <Button style={{marginLeft: 8}} onClick={this.handleFormReset}>
                重置
              </Button>
            </span>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }

  @Bind
  addCoupons(record) {
    const {dispatch} = this.props;
    const {selectedRowKeys} = this.state;
    if (selectedRowKeys.length === 0) {
      message.warn('请先选择优惠券！');
      return;
    }

    Modal.confirm({
      title: '确认添加?',
      content: '添加标的可用优惠券',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        return new Promise((resolve, reject) => {
          dispatch({
            type: 'object/projectCouponsCreate',
            payload: {
              body: {
                ...qs.parse(window.location.search.substr(1)),
                // relationIds: selectedRowKeys,
                coupon: {
                  couponTemplateIds: selectedRowKeys,
                  dictCouponSwitch: '1'
                }
              },
              resolve: () => {
                this.props.changeVisible(false);
                this.props.initData();
                resolve();
              },
              reject,
            }
          })
        });
      }
    });
  }

  @Bind
  rowSelectionChange(selectedRowKeys) {
    this.setState({
      selectedRowKeys
    })
  }

  render() {
    const {visible, form, loading, couponsList, edit} = this.props;
    const {getFieldDecorator} = form;
    const {dataSource, img, imageUrl} = this.state;
    const tableConfig = {
      columns: [
        {
          title: '模版名称',
          dataIndex: 'couponsName',
          key: 'couponsName',
          width: 200,
        },
        {
          title: '额度',
          width: 200,
          key: 'amt',
          dataIndex: 'amt',
        },
        {
          title: '类型',
          width: 200,
          key: 'dictCouponsType',
          dataIndex: 'dictCouponsType',
          render: (text, record) => {
            return this.props.dictionary.getDictValue('couponsType', text);
          }
        },
        {
          title: '使用条件',
          width: 200,
          key: 'couponsDesc',
          dataIndex: 'couponsDesc',
        },
        {
          title: '使用起始期限',
          width: 200,
          key: 'term',
          dataIndex: 'term',
          render: (text, record) => {
            return moment(text).format("YYYY-MM-DD HH:mm:ss");
          }
        },
      ],
      data: couponsList.data || [],
      dataKey: 'couponsId',
      loading: loading.effects['object/queryCouponsForPage'],
      rowSelection: {
        selectedRowKeys: this.state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.rowSelectionChange(selectedRowKeys, selectedRows)
        }
      },
      pagination: {
        showSizeChanger: true,
        total: couponsList.count,
        showQuickJumper: true,
        showTotal: (total) => {
          return '共  ' + total + '  条记录';
        }
      },
      tableOnChange: (pageSize, current, sorterCondition = {}) => {
        const {dispatch} = this.props;
        const {formValues} = this.state;
        this.setState({
          searchFiles: {
            page: current,
            row: pageSize
          }
        });
        dispatch({
          type: 'object/queryCouponsForPage',
          payload: {
            body: {
              page: current,
              row: pageSize,
              ...sorterCondition,
              ...formValues,
              dictValidity: '1',
            }
          }
        })
      }
    };
    return (
      <div>
        <Modal
          className="middle-modal"
          title="优惠券选择"
          visible={visible}
          destroyOnClose={true}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="submit" type="primary" onClick={this.addCoupons}>
              确定
            </Button>,
            <Button key="submit" onClick={this.handleCancel}>
              关闭
            </Button>
          ]}
        >
          {/*{this.renderGeneralForm()}*/}
          <StandardTable tableConfig={tableConfig}/>
        </Modal>
      </div>
    );
  }
}

export default AddCouponsModal;
