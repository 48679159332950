
import Base from './Base';

class BaseLayout extends Base {

  /**
   * 获取path下的所有子路由
   *
   * @param {*} path
   * @param {*} routesData
   * @returns
   * @memberof BasicLayout
   */
  getChildRoutes(path, routesData) {
    return Object.keys(routesData).filter(
      routePath => routePath.indexOf(path) === 0 && routePath !== path && !routesData[routePath].abstract
    ).map((routePath) => routesData[routePath]);
  }

  renderAuthorizedRoutes(routesData) {
    return null;
  }

}

export default BaseLayout;
