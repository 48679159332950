export const creditInfoConfig = {
  "loginAccount":'客户用户名',
  "borrowerName":'客户姓名',
  "dictCertType":'证件类型',
  "certNo":'证件编号',
  "dictCustomerType":'客户角色',
  "amt":"授信金额（元）",
  "creditPeriod":'授信有效期',
  "annualSurvey":'年检周期（月）',
  "creditContractId":"授信合同号",
  "borrowerName":'借款人姓名',
  "dictCustomerBodyType":"主体性质",
  "dictSex":"性别",
  'age': "年龄",
  "dictMaritalStatus":'婚姻状况',
  "residence":'居住地',
  "dictIndustry":'所属行业',
  "dictLoanUsage":'借款用途',
  "dictRepaymentSource":'还款来源',
  "dictContractSign":"合同签约情况",
  "dictGuaranteeType":"担保类型",
  "guaranteeCustomerName":"担保人姓名",
};

export const giveCreditInfo = {
  "loginAccount":'客户用户名',
  "borrowerName":"客户姓名",
  "dictCertType":"证件类型",
  "certNo":"证件编号",
  "dictCustomerType":"客户角色",
  "dictCreditStatus":"授信状态",
  "lastAnnualSurveyTime":"上次年检时间",
};

export const accountInfo = {
  "name":"借款人姓名",
  "sex":"性别",
};

export const blockInfo = {
  "creditInfo":"借款人征信情况",
  "incomeDebtInfo":"借款人收入及负债情况",
  "dueDiligence":"尽职调查",
  "otherDebt":"借款人在其他网络借贷平台借款情况",
  "riskResult":"风险评估及可能产生的风险结果",
  "riskPrompt":"风险提示",
};

export const creditCheckBaseInfo = {
  "loginAccount":'客户用户名',
  "borrowerName":"客户姓名",
  "dictCertType":"证件类型",
  "certNo":"证件编号",
  "dictCustomerType":"客户角色",
  "dictCreditStatus":"授信状态",
  "amt":"授信金额（元）",
  "creditPeriod":'授信有效期',
  'annualSurvey':'年检周期(月)',
  // 'creditContractId':'授信合同号',
  "lastAnnualSurveyTime":"上次年检时间",
};

export const creditCheckAnnounce = {
  "borrowerName":'借款人姓名',
  "dictCustomerType":"主体性质",
  "dictSex":"性别",
  'age': "年龄",
  "dictMaritalStatus":'婚姻状况',
  "residence":'居住地',
  "dictIndustry":'所属行业',
  // "dictLoanUsage":'借款用途',
  // "dictRepaymentSource":'还款来源',
  "dictContractSign":"合同签约情况",
  "dictGuaranteeType":"担保类型",
  "guaranteeCustomerName":"担保人姓名",
};

