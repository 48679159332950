import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css'
import {Bind} from 'lodash-decorators';
import moment from 'moment';
import {connect} from 'dva';
import {upload, getUrl} from '../../services/oss';
import nanoid from 'nanoid';
import { ContentUtils } from 'braft-utils'
import { ImageUtils } from 'braft-finder'
import { Upload, Icon } from 'antd';
import React from 'react';

@connect(({global}) => ({
}))
class BraftEditorWrap extends React.Component {
  constructor(props) {
    super(props);
    let value = '';
    if(props.value) {
      value = props.value
    }
    this.state = {
      value: BraftEditor.createEditorState(value),
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(nextProps.value !== this.props.value) {
        this.setState({
          value: BraftEditor.createEditorState(nextProps.value)
        })
    }
    return true
  }

  @Bind()
  onChange(content) {
    this.setState({
      value: content
    })
    this.props.onChange(content);
  }

  @Bind()
  getFullDate(time) {
    return moment(time).format('YYYY-MM-DD');
  }

  @Bind()
  getRandomFileName(suffix) {
    return `${new Date().getTime()}${Math.round(Math.random() * 1000000)}.${suffix.toLowerCase()}`;
  }

  @Bind()
  uploadHandler({file, onSuccess, onProgress}) {
    upload({
      name: `${this.getFullDate(new Date())}/${this.getRandomFileName(file.name.split('.').reverse()[0])}`,
      file: file,
      callback: (file) => {
        console.log(file);
        this.props.dispatch({
          type: 'global/queryOssConstUrl',
          payload: {
            body: {
              fileRelativeUrls: [file.name]
            },
            callback: (data) => {
              this.setState({
                value: ContentUtils.insertMedias(this.state.value, [{
                  type: 'IMAGE',
                  url: data[0] 
                }])
              },() => {
                this.props.onChange(this.state.value);
                console.log(this.state.value.toHTML());
              })
            }
          }
        })
        // const url = getUrl(file.name, {expires: 3600 * 24 * 365 * 10});
        // console.log(url);
      
        onSuccess({...file});
      },
      progress: (percent) => {
        onProgress({percent: percent * 100});
      }
    });
  }

  render() {
    const extendControls = [
      {
        key: 'antd-uploader',
        type: 'component',
        component: (
          <Upload
            accept="image/*"
            showUploadList={false}
            customRequest={this.uploadHandler}
          >
            {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
            <button type="button" className="control-item button upload-button" data-title="插入图片">
              <Icon type="picture" theme="filled" />
            </button>
          </Upload>
        )
      }
    ]

    const controls = [
      'font-size','font-family','bold', 'line-height',
    'letter-spacing','italic', 'underline','strike-through', 'hr','text-align','text-color', 'separator', 
     'separator', 'remove-styles', 'emoji','undo'
  ];
    return (
      <>
        <BraftEditor
        contentFormat
          disabled={this.props.disabled || false}
          value={this.state.value}
          onChange={this.onChange}
          className="braft-editor"
          controls={controls}
          placeholder="请输入内容"
          extendControls={extendControls}
        />
      </>
    )
  }
}

export default BraftEditorWrap;