import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Bind} from 'lodash-decorators';
import styles from './index.module.less';
import moment from 'moment';
import {upload, getUrl} from '../../services/oss';
import {Button, Upload, Icon, Modal, Progress, message} from 'antd';

class UploadItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      fileList: [],
      loading: false,
      previewVisible: false,
      previewImage: '',
      showRemoveIcon: true,
      imageSrc: null,
    }
  }

  componentDidMount() {
    const {imageTitle, imageUrl, openType} = this.props;
    if (openType) {
      if (openType === 'look') {
        this.setState({
          showRemoveIcon: false
        })
      }
    }
    if(imageUrl) {
      this.setState({
        fileList: [{
          uid: '-1',
          name: imageTitle || '图片',
          status: 'done',
          url: getUrl(imageUrl, {expires: 1800}),
        }],
        imageSrc: getUrl(imageUrl, {expires: 1800}) || null
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.imageUrl && !this.state.imageSrc) {
      this.setState({
        fileList: [{
          uid: '-1',
          name: nextProps.imageTitle || '图片',
          status: 'done',
          url: getUrl(nextProps.imageUrl, {expires: 1800}),
        }],
        imageSrc: getUrl(nextProps.imageUrl, {expires: 1800}) || null
      })
    }
  }

  @Bind
  getFullDate(time) {
    return moment(time).format('YYYY-MM-DD');
  }

  @Bind
  handleChange({fileList}) {
    if(fileList.length === 0) {
      this.setState({
        fileList
      });
      return ;
    }
    const file = fileList[fileList.length - 1];
    if (fileList.length !== 0) {
      if (file.status && file.status === 'uploading') {
        this.setState({loading: true});
      }
    }
    if(file && file.size && file.size <= 1024*1024*3) {
      this.setState({
        fileList
      });
    }
  }

  @Bind
  onRemove() {
    const {setImgStateCallback} = this.props;
    if (setImgStateCallback) {
      setImgStateCallback(null);
    }
    console.log('444',this.state.fileList);
  }

  @Bind
  getRandomFileName(suffix) {
    return `${new Date().getTime()}${Math.round(Math.random() * 1000000)}.${suffix.toLowerCase()}`;
  }

  // @Bind
  // renderUploadProgress (progress = 0) {
  //   return (
  //     <div>
  //       <div className={styles.file_uploading}>文件上传中</div>
  //       <Progress
  //         className={styles.file_progress}
  //         strokeWidth={3}
  //         percent={progress}
  //         showInfo={false}
  //       />
  //     </div>
  //   );
  // }
  @Bind
  customRequest({file, onSuccess, onProgress}) {
    const {setImgStateCallback} = this.props;
    const {files} = this.state;
    upload({
      name: `${this.getFullDate(new Date())}/${this.getRandomFileName(file.name.split('.').reverse()[0])}`,
      file: file,
      callback: (file) => {
        files.push(file);
        this.setState({
          files,
          loading: false
        });
        if (setImgStateCallback) {
          setImgStateCallback(files);
        }
        onSuccess({...file});
      },
      progress: (percent) => {
        onProgress({percent: percent * 100});
      }
    });
  };

  // 预览方法
  @Bind
  handlePreview(file) {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }

  //预览取消事件
  @Bind
  handleCancel() {
    this.setState({
      previewVisible: false
    });
  }

  @Bind
  renderUploadContent() {
    const {files, fileList} = this.state;
    const {openType} = this.props;
    return (
      fileList.length >= 1 ? null :
        <div>
          {openType !== 'look' ?
            <div>
              <Icon type={'plus'}/>
              <div className="ant-upload-text">Upload</div>
            </div> : <div>暂无图片</div>
          }
        </div>
    )
  }

  @Bind
  beforeUpload(file, fileList) {
    if(file.size && file.size > 1024*1024*3) {
      message.warn('上传文件不能大于3M');
      return false;
    }
  }
  render() {
    const {imageUrl, openType, accept} = this.props;
    const {previewImage, previewVisible, fileList, showRemoveIcon, imageSrc} = this.state;
    return (
      <div>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          // showUploadList={false}
          accept={accept || 'image/*'}
          customRequest={this.customRequest}
          fileList={this.state.fileList}
          onPreview={this.handlePreview}
          beforeUpload={this.beforeUpload}
          disabled={openType === 'look' ? true : false}
          showUploadList={
            {showPreviewIcon: true, showRemoveIcon: showRemoveIcon}
          }
          onRemove={this.onRemove}
          onChange={this.handleChange}
        >
          {this.renderUploadContent()}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          {imageUrl && !previewImage ? <img alt="example" style={{width: '100%'}} src={imageSrc}/> :
            <img alt="example" style={{width: '100%'}} src={previewImage}/>}
        </Modal>
      </div>
    );
  }
}

export default UploadItem;
