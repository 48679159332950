
import FormController from './FormController';
import Bind from 'lodash-decorators/bind';

import { currying } from '../utils';

import styles from './TableController.module.less';

class TableController extends FormController {

  get defaultHeaderRowConfig() {
    return () => {
      return {
        onDoubleClick: (event) => {
          event.target.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
        }
      };
    }
  }

  get defaultPaginationConfig() {
    const { count, page, row } = this.props;
    return {
      className: styles['table-pagination'],
      total: count,
      current: page,
      pageSize: row || 10,
      // defaultCurrent: 1,
      // defaultPageSize: row || 10,
      onChange: this.onPaginationChange,
      showTotal: this.showTotal,
      showQuickJumper: true,
      showSizeChanger: true,
      onShowSizeChange: this.onShowSizeChange,
      pageSizeOptions: ['10', '20', '30', '40', '50'],
    };
  };

  /**
   * 重新加载表格数据
   * reload 可能会做些其他操作
   *
   * @memberof TableController
   */
  reload() {
    this.onFetchTableData({page: 1});
  }

  /**
   * 需要具体实现
   * 具体请求数据的方法
   * data是特殊需求的数据，
   * 方法内还能从form中取数据，放到url查询参数中
   *
   * @param {*} data
   * @memberof TableController
   */
  onFetchTableData(data) {
    super.setSearchParams(data);
    // this.props.dispatch({type: '', payload: data});
  }
  /**
   * 方法根据type和传入的参数，提交查询
   *
   * @param {*} type
   * @param {*} data
   * @memberof TableController
   */
  fetchTableData(type, data) {
    const { page, row } = this.props;
    this.props.dispatch({type, payload: {
      page,
      row,
      ...data,
    }});
  }

  componentDidMountWithSearch(form) {
    const params = super.getSearchParams();
    form && form.setFieldsValue(params);
    this.onFetchTableData({page: 1, ...params});
  }

  /**
   * 该方法从表单中取搜索参数，改变url参数，提交搜索
   *
   * @param {*} type
   * @param {*} data
   * @param {*} form
   * @memberof TableController
   */
  fetchTableDataWithSearchForm(type, data, form) {
    const { page, row } = this.props;
    form && form.validateFields({}, (errors, values) => {
      if (!errors) {
        super.setSearchParams(values);
        this.props.dispatch({type, payload: {
          page,
          row,
          ...values,
          ...data,
        }});
      }
    });
  }

  /*
   * 页码改变的回调，参数是改变后的页码及每页条数
   *
   * @memberof TableController
   */
  @Bind
  onPaginationChange(page, pageSize) {
    this.onFetchTableData({page});
  }

  /**
   * 用于显示数据总量和当前数据顺序
   *
   * @param {*} total
   * @param {*} range 当前表格展示的范围
   * @returns
   * @memberof TableController
   */
  @Bind
  showTotal(total, range) {
    return `共 ${total} 条记录`;
  }

  /**
   * pageSize 变化的回调
   *
   * @param {*} current
   * @param {*} size
   * @memberof TableController
   */
  @Bind
  onShowSizeChange(current, size) {
    if (current !== size) {
      this.props.dispatch({type: 'setting/setRow', payload: size});
      // 重新请求数据，直接跳第一页
      this.onFetchTableData({page: 1, row: size});
    }
  }

}

export default TableController;
