
import { Component } from 'react';
import pathToRegexp from 'path-to-regexp';
import { routerRedux } from 'dva/router';
import querystring from 'query-string';
import { Bind } from 'lodash-decorators';

class Base extends Component {
  // 返回的值默认setState，相当于componentWillReceiveProps+setState
  static getDerivedStateFromProps(nextProps, prevState) { return null; }
  componentDidMount() {
  }
  componentDidCatch(error, errorInfo) {
    console.error('error:', error);
    console.error('errorInfo:', errorInfo);
  }
  shouldComponentUpdate(nextProps, nextState, nextContext) { return true; }
  // 返回的值会作为snapshot传入componentDidUpdate
  getSnapshotBeforeUpdate(prevProps, prevState) { return null; }
  componentDidUpdate(prevProps, prevState, snapshot) {}
  componentWillUnmount() {
    // const {dispatch} = this.props;
    // dispatch({
    //   type: 'global/changeExpandForm',
    //   payload: false
    // })
  }


  /**
   * 模板
   * 离开当前路由时调用的默认行为
   * 1、用来清理store数据
   * 2、用来在表单页做提醒，可以通过isFieldsTouched看是否修改过
   *
   * @param {*} location
   * @returns true 允许跳转；false不允许；string 提醒的消息
   * @memberof Base
   */
  @Bind
  onRouteLeave({pathname, search, state, hash}) {
    // const { location } = this.props;
    // 如果是子路由，允许
    // if (pathname.indexOf(location.pathname) === 0) {
    //   return true;
    // }
    return '表单未提交，是否离开当前页面';
  }

  /**
   * 根据路径获取路由对象
   *
   * @param {*} path
   */
  getRoute(path, routesData) {
    const routeRegex = pathToRegexp(path);
    const routeKey = Object.keys(routesData).find((key) => routeRegex.test(key));
    return routesData[routeKey] || {};
  }

  /**
   * 获取页面的title
   *
   * @memberof Base
   */
  getPageTitle(routesData) {
    // 所有路由内的组件都有 match 属性
    const path = this.props.location.pathname;
    return this.getRoute(path, routesData).title;
  }
  /**
   * 获取search中所有的参数
   * 使用query-string，考虑到有数组等情况
   *
   * @returns
   * @memberof Base
   */
  getSearchParams() {
    const { location: {search} } = this.props;
    return querystring.parse(search);
  }
  /**
   * 改变当前url的search参数
   * 使用query-string，考虑到有数组等情况
   *
   * @param {*} [params={}]
   * @memberof Base
   */
  setSearchParams(params = {}) {
    const { dispatch, location: {pathname} } = this.props;
    const search = querystring.stringify(params);
    dispatch(routerRedux.replace({
      pathname,
      search,
      state: params
    }));
  }
}

export default Base;
