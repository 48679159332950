
import React from 'react';
import PropTypes from 'prop-types';

// 默认title使用head中设置的title
const defalutTitle = document.title;

// 用来在路由变更时，自动改变title
const DocumentTitle = ({ title, children }) => {
  document.title = title? `${title} - ${defalutTitle}`: defalutTitle;
  if (children) {
    return React.Children.only(children);
  } else {
    return null;
  }
};

Document.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DocumentTitle;
