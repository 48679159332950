export const accountInfoConfig = {
  "loginAccount":'客户用户名',
  // "accountBalance":'账户余额',
  // "accountUpdateTime":'余额更新时间',
  "accountBalanceString":'账户余额(元)',
  "createTime":'开户时间',
  "bankName":"绑定银行",
  "creditAmt":'授信金额(元)',
  "bindedTime":'绑卡时间',
  "bankAccountNo":"绑定卡号",
  "borrowAmt":'总借款金额(元)',
  "dictRiskAssessmentResults":"风险偏好等级",
  "phoneNo":"银行预留手机号",
  "leftCreditAmt":'授信余额(元)',
  "preLendAmt":'出借上限(元)',
  "dictAccountStatus":'状态',
  "lendAmt":'总出借金额(元)',
  "leftLendAmt":'剩余出借上限(元)',
  "accountNo": '存管电子账号'
};

export  const customerInfo = {
  "customerId":"客户编号",
  "name":"客户姓名",
  "dictCustomerType":"客户角色",
  "dictCertType":"证件类型",
  "certNo":"证件编号",
  "dictSex":"性别",
  "registerTime":"注册时间",
  "registerIp":"注册ip",
  "dictCustomerSource":"注册来源",
  "inviterName":"邀请人姓名",
  "inviterTime":"邀请时间",
  'inviterMobile': '邀请人手机号',
  "dictAccountOpen":"实名开户",
  "birthday":"客户生日",
  "mobile":"手机号",
  "dictCardBind":"是否绑卡",
  "integral":'剩余积分',
}

export const giveCreditInfo = {
  "loginAccount":'客户用户名',
  "name":"客户姓名",
  "dictCertType":"证件类型",
  "certNo":"证件编号",
  "dictCustomerType":"客户角色",
};

export const borrowerInfo = {
  "name":"借款人姓名",
  "sex":"性别",
};

export const receiveInfo = {
  "receiverName":'收件人',
  "receiverPhone":"联系方式",
  "addressDetail":"收货地址",
};
