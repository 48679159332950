
import React, { Component } from 'react';
import {
  Affix,
  Breadcrumb,
  Icon,
} from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'dva/router';

import { getMatchedURLs, getMatchedRoutes } from '../../routes/utils';

import styles from './index.module.less';
import { Bind } from '../../../node_modules/lodash-decorators';

class PageHeader extends Component {
  static propTypes = {
    routesData: PropTypes.object,
    location: PropTypes.object,
    showTitle: PropTypes.bool,
  };

  static defaultProps = {
    showTitle: true,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * 根据当前路由，找到匹配的路由
   *
   * @returns
   * @memberof PageHeader
   */
  getMatchedRoutes() {
    const { routesData, location: {pathname} } = this.props;
    const urls = getMatchedURLs(pathname);
    return getMatchedRoutes(urls, routesData);
  }

  @Bind
  renderBreadItem(route, params, routes, paths) {
    const index = routes.indexOf(route);
    const {title} = route;
    if (index === routes.length - 1) {
      return <span>{title}</span>;
    }
    return (
      <Link to={route.path}>
        {
          index === 0? <Icon style={{fontSize: '14px'}} type="home" />: title
        }
      </Link>
    );
  }

  renderHeaderContent() {
    const { children, showTitle } = this.props;
    if (children) {
      return children;
    } else if (showTitle) {
      return (
        <p className={styles['header-title']}>{document.title.split('-')[0]}</p>
      );
    }
  }
  renderBreadcrumb() {
    const matchedRoutes = this.getMatchedRoutes();
    matchedRoutes.unshift({path: '/'});
    return (
      <Breadcrumb className={styles['breadcrumb']} routes={matchedRoutes} itemRender={this.renderBreadItem}/>
    );
  }
  render() {
    return (
      <Affix
        className={styles['header-wrap']}
        target={() => document.querySelector('.ant-layout-content')}
      >
        <div className={styles['page-header']}>
          { this.renderBreadcrumb() }
          { this.renderHeaderContent() }
        </div>
      </Affix>
    );
  }
}

export default PageHeader;
