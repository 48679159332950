
import pathToRegexp from 'path-to-regexp';

/**
 * 从url获取匹配的路由key
 * /index/home => /index, /index/home
 *
 * @export
 * @param {*} pathname
 * @returns
 */
export function getMatchedURLs(pathname) {
  const urlList = pathname.split('/').slice(1);
  return urlList.map((item, index) => `/${urlList.slice(0, index+1).join('/')}`);
}


/**
 * 根据path找到，匹配的路由对象
 *
 * @export
 * @param {*} path
 * @param {*} routesData
 * @returns
 */
export function getMatchedRoute(path, routesData) {
  const routePath =  Object.keys(routesData).find((routePath) => pathToRegexp(routePath).test(path));
  return routesData[routePath];
}

export function getMatchedRoutes(pathes, routesData) {
  return pathes.map((path) => getMatchedRoute(path, routesData)).filter(((route) => route));
}

/**
 * 将层级的菜单对象，转换成url为key的对象
 *
 * @memberof SiderMenu
 */
export function getFlatMenus(menusData) {
  let flatMenus = {};
  menusData.map((menu) => {
    flatMenus[menu.path] = menu;
    flatMenus = {
      ...flatMenus,
      ...menu.children.reduce((prev, subMenu) => {
        prev[subMenu.path] = subMenu;
        return prev;
      }, {})
    };
    return null;
  });
  return flatMenus;
}
