
import React from 'react';
import { Modal } from 'antd';

const defaultProps = {
  keyboard: false,
  maskClosable: false,
};

const CustomModal = (props) => {
  return (
    <Modal
      {...defaultProps}
      {...props}
    >
      { props.children }
    </Modal>
  );
}

export default CustomModal;
